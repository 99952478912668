define("apollo/pods/components/transports/transports-table/transport-row/component", ["exports", "apollo/config/environment", "apollo/mixins/checkpoint-mixin"], function (_exports, _environment, _checkpointMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_checkpointMixin.default, {
    init() {
      this._super(...arguments);

      const transport = this.get('transport');
      const masterTransportId = transport.get('combinedMasterTransport.id');

      if (masterTransportId !== undefined) {
        this.set('transport.loading', true);
        this.get('store').findRecord('transport', masterTransportId).then(masterTransport => {
          this.set('transport.loading', false);
          this.set('masterTransport', masterTransport);
        });
      }
    },

    tagName: 'tr',
    classNames: Ember.A(['transport-row']),
    attributeBindings: Ember.A(['data-order-number']),
    classNameBindings: Ember.A(['transport.isHighlighted:checked-transport-row']),

    click() {
      if (this.get('isTransportRowHighlightingEnabled')) {
        this.toggleProperty('transport.isHighlighted');
        this.updateHighlightedRow(this.get('transport.id'));
      }
    },

    sessionAccount: Ember.inject.service('session-account'),
    // uzywane w template
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    relationsTrackerService: Ember.inject.service('transport-relations.relations-tracker'),
    multiArchiveTrackerService: Ember.inject.service(),
    apolloApiService: Ember.inject.service('api.apollo-api-service'),
    transport: null,
    masterTransport: null,
    action: '',
    advice: Ember.computed('masterTransport', 'transport.advice', 'masterTransport.advice', function () {
      const masterTransportOfCombinedTransportExists = this.get('masterTransport') !== null;

      if (masterTransportOfCombinedTransportExists) {
        return this.get('masterTransport.advice');
      }

      return this.get('transport.advice');
    }),
    maxCharactersInOrderNumberColumn: Ember.computed(function () {
      return this.get('sessionAccount').getSettingValue('MAX_CHARACTERS_IN_ORDER_NUMBER_COLUMN');
    }),
    maxCharactersInProductsColumn: Ember.computed(function () {
      return this.get('sessionAccount').getSettingValue('MAX_CHARACTERS_IN_INDEX_PRODUCTS_COLUMN');
    }),
    maxCharactersInTransportRelationColumn: Ember.computed(function () {
      return this.get('sessionAccount').getSettingValue('MAX_CHARACTERS_IN_TRANSPORT_RELATION_COLUMN');
    }),
    courierErrorLabel: Ember.computed(function () {
      return this.get('intl').t('common.error').toUpperCase();
    }),
    showCourierSpinner: Ember.computed('transport.{transaction,carrierCompany,getCouriersQuotes}', function () {
      return !!(this.get('transport.transaction.content') && this.get('transport.carrierCompany.content') && this.get('transport.getCouriersQuotes'));
    }),
    transportNotReadyToPlan: Ember.computed('transport.readyToPlan', function () {
      return !this.get('transport.readyToPlan');
    }),
    hasMasterTransport: Ember.computed('masterTransport', function () {
      if (this.get('transport.isMasterTransportInTransportCombining')) {
        return false;
      }

      return this.get('masterTransport') !== null;
    }),
    showLoadTimeWindowsOfMasterTransport: Ember.computed('hasMasterTransport', 'masterTransport.loadWarehouses.length', function () {
      return this.get('hasMasterTransport') && this.get('masterTransport.loadSteps').any(step => !!step.get('timeWindow.id'));
    }),
    showUnloadTimeWindowsOfMasterTransport: Ember.computed('hasMasterTransport', 'masterTransport.unloadWarehouses.length', function () {
      return this.get('hasMasterTransport') && this.get('masterTransport.loadSteps').any(step => !!step.get('timeWindow.id'));
    }),
    masterTransportLoadTimeWindows: Ember.computed('masterTransport.timeWindows.@each.start', function () {
      const masterTransport = this.get('masterTransport');
      return masterTransport.get('loadSteps').filter(function (s) {
        return s.get('timeWindow') && !s.get('timeWindow.deleted');
      }).map(function (s) {
        return s.get('timeWindow');
      });
    }),
    masterTransportUnloadTimeWindows: Ember.computed('masterTransport.timeWindows.@each.start', function () {
      const masterTransport = this.get('masterTransport');
      return masterTransport.get('unloadSteps').filter(function (s) {
        return s.get('timeWindow') && !s.get('timeWindow.deleted');
      }).map(function (s) {
        return s.get('timeWindow');
      });
    }),
    transportHasEmailAdvice: Ember.computed('transport.emailAdvices.@each.sent', function () {
      const emailAdvice = this._getCurrentEmailAdviceForTransport('ADVICE_DATA');

      return emailAdvice ? emailAdvice.get('sent') : false;
    }),
    transportHasEmailAdviceCf: Ember.computed('transport.emailAdvices.@each.sent', function () {
      const emailAdvice = this._getCurrentEmailAdviceForTransport('CF_DATA');

      return emailAdvice ? emailAdvice.get('sent') : false;
    }),
    currentEmailAdviceEmail: Ember.computed('transport.emailAdvices.@each.expired', function () {
      const emailAdvice = this._getCurrentEmailAdviceForTransport('ADVICE_DATA');

      return emailAdvice ? emailAdvice.get('email') : Ember.String.htmlSafe('&nbsp;');
    }),
    currentEmailAdviceCfEmail: Ember.computed('transport.emailAdvices.@each.expired', function () {
      const emailAdvice = this._getCurrentEmailAdviceForTransport('CF_DATA');

      return emailAdvice ? emailAdvice.get('email') : null;
    }),
    _getCurrentEmailAdviceForTransport: function (type) {
      let emailAdvice = null;

      if (this.get('transport.completed')) {
        emailAdvice = this.get('transport.emailAdvices').filter(ea => {
          return ea && ea.get('type') === type;
        }).sortBy('dateCreated').reverse().get('firstObject');
      } else {
        emailAdvice = this.get('transport.emailAdvices').filter(ea => {
          return ea && ea.get('expired') === false && ea.get('type') === type;
        }).get('firstObject');
      }

      return emailAdvice;
    },
    transportDescriptionMaxLength: Ember.computed(function () {
      let maxChars = Number(this.get('sessionAccount').getSettingValue('TRANSPORT_DESCRIPTION_COLUMN_MAX_CHARS'));

      if (!maxChars) {
        maxChars = 100;
      }

      return maxChars;
    }),
    userHasEmailAdviceRole: Ember.computed(function () {
      return this.get('transport').actionCanBePerformed('ROLE_CAN_SEND_EMAIL_ADVICE');
    }),
    userHasEmailAdviceCfRole: Ember.computed(function () {
      return this.get('transport').actionCanBePerformed('ROLE_CAN_SEND_EMAIL_ADVICE_CF');
    }),
    userCanAddEmailAdvice: Ember.computed(function () {
      return this.get('transport').actionCanBePerformed('ROLE_CAN_SEND_EMAIL_ADVICE');
    }),
    userCanAddEmailAdviceCf: Ember.computed(function () {
      return this.get('transport').actionCanBePerformed('ROLE_CAN_SEND_EMAIL_ADVICE_CF');
    }),
    showCourierDocuments: Ember.computed('transport.courierOrder.{tntAccessCode,documents.length}', function () {
      return this.get('transport.courierOrder.tntAccessCode') || this.get('transport.courierOrder.documents.length') > 0;
    }),
    showTNTCourierDocuments: Ember.computed('transport.courierOrder', function () {
      return this.get('transport.courierOrder.tntAccessCode') !== null;
    }),
    showDHLCourierDocuments: Ember.computed('transport.courierOrder.documents.length', function () {
      return this.get('transport.courierOrder.documents.length') > 0;
    }),
    _trackingListLinkFactory: function (courierName, trackingNumber) {
      switch (courierName) {
        case 'TNT':
          return `https://www.tnt.com/express/en_gb/site/shipping-tools/tracking.html?utm_redirect=legacy_track&respCountry=pl&respLang=pl&navigation=1&page=1&sourceID=1&sourceCountry=ww&plazaKey=&refs=&requesttype=GEN&searchType=CON&cons=${trackingNumber}`;

        case 'DHL EXPRESS':
          return `https://mydhl.express.dhl/pl/pl/tracking.html#/results?id=${trackingNumber}`;

        case 'DHL PL':
          return `https://www.dhl.com/pl-en/home/tracking/tracking-parcel.html?submit=1&tracking-id=${trackingNumber}`;

        default:
          return null;
      }
    },
    _getCourierName: function () {
      return this.get('store').findRecord('transaction', this.get('transport.transaction.id')).then(courierTransaction => {
        return courierTransaction.get('carrier.name').toUpperCase();
      });
    },
    canMultiArchive: Ember.computed('transport', function () {
      return this.get('sessionAccount').hasRole('ROLE_CAN_MULTI_ARCHIVE_TRANSPORTS');
    }),
    canEditFreight: Ember.computed('transport', function () {
      return this.get('transport').actionCanBePerformed('EDIT_TRANSACTION_FREIGHT');
    }),
    showPassCourierButton: Ember.computed('transport.{activeAuction,transaction.id}', function () {
      const transportHasNoActivePassingAction = !this.get('transport.transaction.id') && !this.get('transport.activeAuction');
      const canPassToCourier = this.get('transport').actionCanBePerformed('PASS_COURIER');
      const transportHasCourierQuotesEnabled = this.get('transport.getCouriersQuotes');
      return transportHasNoActivePassingAction && canPassToCourier && transportHasCourierQuotesEnabled;
    }),
    showAutoPassButton: Ember.computed('transport.{activeAuction,transaction.id}', function () {
      const hasActiveTransaction = !!this.get('transport.transaction.id');
      const transportHasNoActiveAuction = !this.get('transport.activeAuction');
      const canAutoPass = this.get('transport').actionCanBePerformed('ROLE_AUTOMATIC_PASS_TRANSPORT_TO_ROUTE_OFFERS');
      const properTransportType = this.get('transport.transportType.allowsPriceList');
      return !hasActiveTransaction && canAutoPass && properTransportType && transportHasNoActiveAuction;
    }),
    showPassTransportButton: Ember.computed('transport.{transaction.id,activeAuction,transportType,checkpoints.@each.executionDate}', function () {
      const transportType = this.get('transport.transportType');
      const transactionSetting = this.get('sessionAccount').getTransactionSetting(transportType);
      const canChooseCarrierFromTransportTable = transactionSetting ? transactionSetting.get('canChooseCarrierFromTransportTable') : false;
      const transportHasNoActivePassingAction = !this.get('transport.transaction.id') && !this.get('transport.activeAuction');
      const passingActionCanBePerformed = this.get('transport').actionCanBePerformed('PASS_TRANSPORT');
      return transportHasNoActivePassingAction && passingActionCanBePerformed && canChooseCarrierFromTransportTable;
    }),
    showAcceptAndRejectTransactionButton: Ember.computed('transport.{transaction.content,carrierCompany.id,checkpoints.@each.executionDate,transaction.carrier.id}', function () {
      const carrierCompanyId = this.get('transport.transaction.carrier.id');
      const currentCompanyId = this.get('sessionAccount.currentUser.company.id');
      const isPrincipalUser = this.get('sessionAccount.currentUser.isPrincipal');
      return this.get('transport.transaction.content') && !this.get('transport.carrierCompany.id') && this.get('transport').actionCanBePerformed('ACCEPT_AND_REJECT_TRANSPORT') && (carrierCompanyId === currentCompanyId || isPrincipalUser);
    }),
    disableAcceptAndRejectButton: Ember.computed('sessionAccount.{sessionAccount.currentUser.company,currentUser.company.partnershipType}', function () {
      const partnershipType = this.get('sessionAccount').get('currentUser').get('company.partnershipType.name');
      return partnershipType === 'SUPPLIER';
    }),
    showPencil: Ember.computed('advice', function () {
      return this.get('advice') && this.get('sessionAccount').getPositiveAuthorityRestrictionsByTransportTypeId(this.get('transport.transportType.id')).filter(ar => {
        return ['MOVE_ADVICE_WINDOW'].includes(ar.get('authority.name'));
      });
    }),
    transactionWaitingForAcceptance: Ember.computed('transport.{transaction.id,carrierCompany.id}', function () {
      const positiveAuthorityRestrictions = this.get('transport.transportType.content').findPositiveAuthorityRestrictionsByActionType('PASS_TRANSPORT', this.get('transport.transportType.id'));
      return this.get('transport.transaction.id') && positiveAuthorityRestrictions.get('length') !== 0 && !this.get('transport.carrierCompany.id');
    }),
    indexesTransportNumbersAsArray: Ember.computed('transport.indexes', function () {
      return this.get('transport.indexes').filterBy('transportNumber').getEach('transportNumber').uniq();
    }),
    indexesDocumentNumbersAsArray: Ember.computed('transport.indexes', function () {
      return this.get('transport.indexes').filterBy('documentNumber').getEach('documentNumber').uniq();
    }),
    showWeightReceiptDownloadButton: Ember.computed('transport.{warehousesWithWeightIncluded.length,weighingTasks.length,transportType.generatingWeightReceiptEnabled}', function () {
      return this.get('sessionAccount.currentUser.roleGroup.isGuard') && this.get('transport.warehousesWithWeighingIncluded.length') > 0 && this.get('transport.weighingTasks.length') > 0 && this.get('transport.transportType.generatingWeightReceiptEnabled');
    }),
    adviceTrailerRegistrationNumber: Ember.computed('advice', 'advice.trailerRegistrationNumber', function () {
      return this.get('advice').get('trailerRegistrationNumber');
    }),
    adviceCarRegistrationNumber: Ember.computed('advice', 'advice.carRegistrationNumber', function () {
      return this.get('advice').get('carRegistrationNumber');
    }),
    allConfirmedTaskGuardCheckpoints: Ember.computed('transport.checkpointsThatCanBeConfirmedByGuardRole.@each.executionDate', 'transportType.guardUniqueSortedTasks', function () {
      return this.get('transport.checkpointsThatCanBeConfirmedByGuardRole').filterBy('executionDate').sortBy('executionDate').reverse();
    }),
    uniqueTaskGuardCheckpoints: Ember.computed('transport.{checkpointsThatCanBeConfirmedByGuard,checkpointsThatCanBeConfirmedByGuard.[],checkpoints.@each.executionDate}', 'transportType.guardUniqueSortedTasks', function () {
      const currentUserWarehousesIds = this.get('sessionAccount').get('currentUser.warehouses').getEach('id');
      const checkpoints = Ember.A();
      this.get('transportType.guardUniqueSortedTasks').forEach(t => {
        let checkpoint = this.get('transport.checkpointsThatCanBeConfirmedByGuard').find(c => c.get('task.alias') === t.get('alias') && !c.get('executionDate'));

        if (!checkpoint) {
          checkpoint = this.get('transport.checkpointsThatCanBeConfirmedByGuard').find(c => c.get('task.alias') === t.get('alias') && c.get('executionDate'));
        }

        if (!checkpoint) {
          return;
        }

        const cp = Ember.ObjectProxy.create({
          content: checkpoint,
          task: t
        });

        if (currentUserWarehousesIds.includes(cp.get('step.warehouse.id'))) {
          checkpoints.pushObject(cp);
        }
      });
      return checkpoints.sortBy('task.taskGroupIdentifier', 'task.idxInGroup').filterBy('task.enabled');
    }),
    uniqueTaskGuardCheckpointsIds: Ember.computed('transport.{checkpointsThatCanBeConfirmedByGuardRole,checkpointsThatCanBeConfirmedByGuard.[],checkpoints.@each.executionDate}', 'transportType.guardUniqueSortedTasks', function () {
      return this.get('uniqueTaskGuardCheckpoints').getEach('id');
    }),
    showSingleColumnLayout: Ember.computed('', function () {
      return this.get('transport').get('transportType.guardCheckpointConfirmationSingleColumnLayout');
    }),
    showTransportDetails: Ember.computed('', function () {
      return this.get('sessionAccount').hasRole('ACCESS_TO_TRANSPORT_DETAILS');
    }),
    showProducts: Ember.computed('transport.products', function () {
      const productsArr = this.get('transport.products');
      return productsArr ? productsArr.join(',').replaceAll(',', ', \n') : '';
    }),

    _openDocumentWindow(documentUrl, orderId) {
      return window.open(_environment.default.serverURL + documentUrl + orderId + '?token=' + this.get('sessionAccount').get('token'), '_blank');
    },

    combiningTransportsCanBeAdded: Ember.computed('transport', function () {
      return this.get('transport').actionCanBePerformed('ROLE_CAN_ADD_TRANSPORT_COMBINING_RELATION');
    }),
    hasToWaitForCanBeAdded: Ember.computed('transport', function () {
      return this.get('transport').actionCanBePerformed('ROLE_CAN_ADD_HAS_TO_WAIT_FOR_RELATION');
    }),
    showInspectionButton: Ember.computed('transport.checkpoints.@each.executionDate', function () {
      return this.get('transport').actionCanBePerformed('ROLE_ACCESS_TO_INSPECTION_BUTTON') && this.get('transport.requiresInspection');
    }),
    showFillInspectionText: Ember.computed('transport.customFieldPairs.@each.{definition,value}', 'transport.lastUpdated', function () {
      const customFields = this.get('transport.customFields');
      const filteredCustomFields = customFields.filter(cf => {
        return cf.get('definition.visibleInInspectionPanelSection') && cf.get('definition.options').any(option => option.allowsInspectionPanel);
      });
      if (filteredCustomFields.length === 0) return true;
      return filteredCustomFields.any(cf => {
        return cf.get('option.name') === undefined;
      });
    }),
    filledInspectionText: Ember.computed('transport.customFieldPairs.@each.{definition,value}', 'transport.lastUpdated', function () {
      const customFields = this.get('transport.customFields');
      return customFields.filter(cf => {
        return cf.get('definition.visibleInInspectionPanelSection') && cf.get('definition.options').any(option => option.allowsInspectionPanel);
      }).get('firstObject.option.name');
    }),
    combinedMasterTransportTimeWindows: Ember.computed('transport.timeWindows.length', function () {
      const combinedMasterTransportId = this.get('transport.combinedMasterTransport.id');
      return this.get('store').findRecord('transport', combinedMasterTransportId).then(transport => {
        return transport.get('loadTimeWindows');
      });
    }),
    showMultiSelectCheckbox: Ember.computed('showMultiArchiveCheckbox', 'showRelationCheckbox', function () {
      return this.get('showMultiArchiveCheckbox') || this.get('showRelationCheckbox');
    }),
    showMultiArchiveCheckbox: Ember.computed('transport', function () {
      return this.get('transport').actionCanBePerformed('ROLE_CAN_MULTI_ARCHIVE_TRANSPORTS');
    }),
    transportAndDeliveriesCustomFieldPairs: Ember.computed('transport.{customFieldPairs.[],deliveryCustomFieldPairs.[]}', function () {
      const cfs = this.get('transport.customFieldPairs').slice();
      const dcfs = this.get('transport.deliveryCustomFieldPairs').slice();
      return cfs.concat(dcfs).filterBy('definition.visibleInTable').sortBy('definition.idx');
    }),
    customFieldsVisibleInTable: Ember.computed('transport', 'transport.transportType', function () {
      const cfs = this.get('transport.transportType').get('customFieldDefinitions');
      return cfs.filterBy('enabled').filterBy('visibleInTable').sortBy('idx');
    }),
    deliveriesNetWeight: Ember.computed('transport.deliveries.@each.netWeight', function () {
      return this.get('transport.deliveries').map(d => {
        return d.get('netWeight');
      });
    }),
    deliveriesIncoterms: Ember.computed('transport.deliveries.@each.incoterms', function () {
      const incoterms = this.get('transport.deliveries').map(d => {
        return d.get('incoterms');
      });
      return incoterms ? incoterms.toString() : '';
    }),
    showRelationCheckbox: Ember.computed('availableRelations', 'canCombineTransports', 'relationsTrackerService.currentTransports.{[],length,firstUnconfirmedCheckpoint}', 'transport.{masterTransports.length,relatedToTransports.length,transportType.alias,firstUnconfirmedCheckpoint}', function () {
      if (this.get('transport.completed') || this.get('transport.archived') || this.get('transport.deleted')) {
        return false;
      }

      const masterTransportTypes = Ember.A([]);
      const relatedTransportTypes = Ember.A([]);
      const availableRelations = this.get('availableRelations');

      if (!availableRelations) {
        return false;
      }

      availableRelations.forEach(r => {
        masterTransportTypes.pushObject(r.primaryTransportType.get('id'));
        relatedTransportTypes.pushObject(r.subordinateTransportType.get('id'));
      });

      if (this.get('relationsTrackerService.currentTransports.length') === 0) {
        // Brak zaznaczonych transportów, pokazujemy checkbox przy wszystkich typach które maja opcje relacji i zezwalaja na to uprawnienia
        const correctTransportType = masterTransportTypes.includes(this.get('transport.transportType.id')) || relatedTransportTypes.includes(this.get('transport.transportType.id'));
        const actionCanBePerformed = this.get('transport').actionCanBePerformed('ROLE_CAN_ADD_TRANSPORT_COMBINING_RELATION') || this.get('transport').actionCanBePerformed('ROLE_CAN_ADD_HAS_TO_WAIT_FOR_RELATION');
        return this.get('canCombineTransports') && correctTransportType && actionCanBePerformed;
      } // Zaznaczony minimum 1 transport


      const masterTransport = this.get('relationsTrackerService.currentTransports.firstObject');

      if (!masterTransport) {
        return false;
      }

      const masterTransportType = masterTransport.get('transportType');

      if (this.get('relationsTrackerService.currentTransports').some(t => {
        return t.get('id') === this.get('transport.id');
      })) {
        // Nie znikamy czekboxow zaznaczonych już transportów
        return true;
      }

      const currentTransportType = this.get('transport.transportType');
      const currentRelTransportCombining = availableRelations.find(relation => {
        const transportCombiningRelation = relation.get('alias') === 'TRANSPORT_COMBINING';

        if (!transportCombiningRelation) {
          return false;
        }

        const transportsToCombineIsTheSameTransportType = this.get('relationsTrackerService.currentTransports').every(transport => {
          return transport.get('transportType.id') === currentTransportType.get('id');
        });
        const primaryTransportTypeIsTheSameAsCurrentTransportType = relation.get('primaryTransportType.id') === currentTransportType.get('id');
        const subordinateTransportTypeIsTheSameAsCurrentTransportType = relation.get('subordinateTransportType.id') === currentTransportType.get('id');

        if (transportsToCombineIsTheSameTransportType) {
          return primaryTransportTypeIsTheSameAsCurrentTransportType && subordinateTransportTypeIsTheSameAsCurrentTransportType;
        }

        const primaryTransportTypeIsTheSameAsMasterTransportType = relation.get('primaryTransportType.id') === masterTransportType.get('id');
        const subordinateTransportTypeIsTheSameAsMasterTransportType = relation.get('subordinateTransportType.id') === masterTransportType.get('id');
        return (subordinateTransportTypeIsTheSameAsCurrentTransportType || primaryTransportTypeIsTheSameAsCurrentTransportType) && (primaryTransportTypeIsTheSameAsMasterTransportType || subordinateTransportTypeIsTheSameAsMasterTransportType);
      });
      const currentRelHasToWaitFor = availableRelations.find(relation => {
        if (this.get('relationsTrackerService.currentTransports').every(t => {
          return t.get('transportType.id') === currentTransportType.get('id');
        })) {
          return relation.get('primaryTransportType.id') === currentTransportType.get('id') && relation.get('subordinateTransportType.id') === currentTransportType.get('id') && relation.get('alias') === 'HAS_TO_WAIT_FOR';
        }

        return (relation.get('subordinateTransportType.id') === currentTransportType.get('id') || relation.get('primaryTransportType.id') === currentTransportType.get('id')) && (relation.get('primaryTransportType.id') === masterTransportType.get('id') || relation.get('subordinateTransportType.id') === masterTransportType.get('id')) && relation.get('alias') === 'HAS_TO_WAIT_FOR';
      });
      let transportsCanBeCombined = this.get('combiningTransportsCanBeAdded') && !!currentRelTransportCombining;
      let transportsCanBeRelated = this.get('hasToWaitForCanBeAdded') && !!currentRelHasToWaitFor;
      const loadWhs = this.get('transport.loadWarehouses');
      const unloadWhs = this.get('transport.unloadWarehouses');
      const masterLoadWhs = masterTransport.get('loadWarehouses');
      const masterUnloadWhs = masterTransport.get('unloadWarehouses');

      if (transportsCanBeCombined) {
        // Wyświetlamy checkbox, jeśli jest dostępna i możliwa do dododania relcja typu TRANSPORT_COMBINING,
        // dla zaznaczonego transportu głównego i danego transportu.
        if (currentRelTransportCombining.get('requiresTheSameWarehouseInTheSameStepType')) {
          // Jeśli relacja ma flage requiresTheSameWarehouseInTheSameStepType,
          // to checkbox pokazujemy, tylko jeśli transporty chociaz jeden ten sam magazyn w tych samych typach stepów.
          if (loadWhs && loadWhs.length > 0) {
            const ids = [];
            masterLoadWhs.forEach(wh => {
              if (wh.get('id')) {
                ids.pushObject(wh.get('id'));
              }
            });

            if (transportsCanBeCombined && loadWhs.some(wh => ids.includes(wh.get('id')))) {
              return true;
            }
          }

          if (unloadWhs && unloadWhs.length > 0) {
            const ids = [];
            masterUnloadWhs.forEach(wh => {
              if (wh.get('id')) {
                ids.pushObject(wh.get('id'));
              }
            });

            if (transportsCanBeCombined && unloadWhs.some(wh => ids.includes(wh.get('id')))) {
              return true;
            }
          }

          transportsCanBeCombined = false;
        }
      }

      if (transportsCanBeRelated) {
        // Wyświetlamy checkbox, jeśli jest dostępna i możliwa do dododania relcja typu HAS_TO_WAIT_FOR,
        // dla zaznaczonego transportu głównego i danego transportu.
        if (currentRelHasToWaitFor.get('requiresTheSameWarehouseInDifferentStepType')) {
          // Jeśli relacja ma flage requiresTheSameWarehouseInDifferentStepType,
          // to checkbox pokazujemy, tylko jeśli transporty chociaz jeden ten sam magazyn w przeciwnych typach stepów.
          if (loadWhs && loadWhs.length > 0) {
            const ids = [];
            masterUnloadWhs.forEach(wh => {
              if (wh.get('id')) {
                ids.pushObject(wh.get('id'));
              }
            });

            if (transportsCanBeRelated && loadWhs.some(wh => ids.includes(wh.get('id')))) {
              return true;
            }
          }

          if (unloadWhs && unloadWhs.length > 0) {
            const ids = [];
            masterLoadWhs.forEach(wh => {
              if (wh.get('id')) {
                ids.pushObject(wh.get('id'));
              }
            });

            if (transportsCanBeRelated && unloadWhs.some(wh => ids.includes(wh.get('id')))) {
              return true;
            }
          }

          transportsCanBeRelated = false;
        }
      }

      return transportsCanBeCombined || transportsCanBeRelated;
    }),
    _transportOrRelatedTransportWithHighestNumOfWindows: function (transport) {
      if (!transport.get('isCombinedTransport')) {
        return transport;
      }

      return transport.get('allCombinedTransports').filterBy('isRelatedTransportInTransportCombining').sortBy('stepsWithWarehouse.length').get('lastObject');
    },
    latestManualNotification: Ember.computed('transport.manualNotification.id', function () {
      return this.get('transport.manualNotification');
    }),

    _addToRelationsTracker(selectedTransport) {
      if (!this.get('showRelationCheckbox')) {
        return;
      }

      const alreadyExists = this.relationsTrackerService.findExisting(selectedTransport);

      if (alreadyExists) {
        this.relationsTrackerService.removeRelation(selectedTransport);
        return;
      }

      this.relationsTrackerService.addRelation(selectedTransport);
    },

    _addToMultiArchiveTracker(selectedTransport) {
      if (!this.get('showMultiArchiveCheckbox')) {
        return;
      }

      const alreadyExists = this.multiArchiveTrackerService.findExisting(selectedTransport);

      if (alreadyExists) {
        this.multiArchiveTrackerService.removeTransport(selectedTransport);
        return;
      }

      this.multiArchiveTrackerService.addTransport(selectedTransport);
    },

    checkboxState: Ember.computed('transport.id', function () {
      const selectedTransport = this.get('transport');
      return this.relationsTrackerService.findExisting(selectedTransport) || this.multiArchiveTrackerService.findExisting(selectedTransport);
    }),
    showTransactionRejectedLabel: Ember.computed('transport.{lastRejectedTransactionCarrierShortName,transaction.id}', function () {
      return this.get('transport.lastRejectedTransactionCarrierShortName') && !this.get('transport.carrierCompany.id') && this.get('sessionAccount.currentUser.isPrincipal');
    }),
    actions: {
      toggleTransportPresenceInCheckboxTrackersLists: function () {
        this.set('checkboxState', !this.get('checkboxState'));
        const selectedTransport = this.get('transport');

        if (this.get('checkboxState')) {
          this._addToRelationsTracker(selectedTransport);

          this._addToMultiArchiveTracker(selectedTransport);

          selectedTransport.set('transportType.transportsTabVisited', false);
          return;
        }

        this.relationsTrackerService.removeRelation(selectedTransport);
      },
      editFreight: function (transport) {
        if (!this.get('canEditFreight')) {
          return;
        }

        const transaction = this.get('store').peekRecord('transaction', transport.get('transaction.id'));
        const model = {
          transaction,
          initialCurrency: transaction.get('currency')
        };
        this.set('action', 'showModal');
        this.sendAction('action', 'transaction.modals.edit-freight', model);
      },
      showTransportModal: function () {
        const transport = this.get('transport');
        this.set('openTransportId', transport.get('id'));
        this.get('transport.customFields').forEach(cf => cf.set('changeMessage', null));

        transport._createAmountOfCover();

        this.set('action', 'showModal');
        this.sendAction('action', 'transports.modals.create-transport', transport);
      },
      showModal: function (templateName = 'transports.modals.create-transport', model = null) {
        const transport = this.get('transport');

        if (transport.get('id')) {
          transport.reload();
        }

        transport._createAmountOfCover();

        this.set('action', 'showModal');
        this.sendAction('action', templateName, model || transport);
      },
      goToWarehouse: function (step) {
        this.goToWarehouse(step);
      },
      confirm: function (checkpoint) {
        if (checkpoint.get('task.canConfirmPrevious') && checkpoint.get('id') !== this.get('transport.firstUnconfirmedCheckpoint.id') && !confirm(this.get('intl').t('task.canConfirmPreviousConfirm'))) {
          return;
        }

        this.confirm(checkpoint);
      },
      revoke: function (checkpoint) {
        this.revoke(checkpoint);
      },

      generateWeightReceipt() {
        this.set('action', 'generateWeightReceipt');
        this.sendAction('action', this.get('transport.id'));
      },

      markNotesAsReadOff(transport) {
        this.set('action', 'markNotesAsReadOff');
        this.sendAction('action', transport);
      },

      addNote(noteContent, transportId) {
        this.set('action', 'addNote');
        this.sendAction('action', noteContent, transportId);
      },

      fileUploadSuccess(transportId) {
        this.set('action', 'fileUploadSuccess');
        this.sendAction('action', transportId);
      },

      deleteFile(file) {
        this.set('action', 'deleteFile');
        this.sendAction('action', file);
      },

      fileUploadFail: function (transportId) {
        this.set('action', 'fileUploadFail');
        this.sendAction('action', transportId);
      },
      acceptTransaction: function () {
        const acceptTransactionButton = $('.accept-transaction-button');
        acceptTransactionButton.button('loading');
        const self = this;
        const transaction = self.get('transport.transaction.content');
        transaction.set('isRejected', false);
        transaction.save().then(() => {
          acceptTransactionButton.button('reset');
          console.debug(`User has accepted transport ${self.get('transport.id')}.`);
          self.set('transport.transportType.forcedPollRequestTime', new Date().getTime());
        }).catch(response => {
          acceptTransactionButton.button('reset');
          alert(response.errors[0]);
        });
      },
      rejectTransaction: function () {
        const rejectTransactionButton = $('.reject-transaction-button');
        rejectTransactionButton.button('loading');
        const self = this;
        const transaction = self.get('transport.transaction.content');
        transaction.set('isRejected', true);
        transaction.save().then(() => {
          rejectTransactionButton.button('reset');
          console.debug(`User has rejected transport ${self.get('transport.id')}.`);
          self.set('transport.transportType.forcedPollRequestTime', new Date().getTime());
        }).catch(response => {
          rejectTransactionButton.button('reset');
          alert(response.errors[0]);
        });
      },
      getCourierDocument: function (documentType, documentId) {
        const orderId = this.get('transport.courierOrder.id');

        switch (documentType) {
          case 'MANIFEST':
            Ember.run.once(this, this._openDocumentWindow('/courier/getManifest/', orderId));
            break;

          case 'LABEL':
            Ember.run.once(this, this._openDocumentWindow('/courier/getLabel/', orderId));
            break;

          case 'CONNOTE':
            Ember.run.once(this, this._openDocumentWindow('/courier/getConnote/', orderId));
            break;

          case 'DHL':
            Ember.run.once(this, this._openDocumentWindow('/courier/getDhlCourierDocument/', orderId + '/' + documentId));
            break;

          default:
            console.error('Wrong documentType to download!');
        }
      },
      getCourierOrderErrors: function () {
        this.set('action', 'showModal');
        const transport = this.get('transport');
        const model = {
          transport
        };
        this.sendAction('action', 'transaction.modals.courier-errors', model);
      },
      openEmailAdviceModal: function (type) {
        this.set('action', 'showModal');
        const transport = this.get('transport');

        const emailAdvice = this._getCurrentEmailAdviceForTransport(type);

        const model = this.get('store').createRecord('emailAdvice', {
          transport,
          type
        });

        if (emailAdvice) {
          model.set('email', emailAdvice.get('email'));
          model.set('rodoAccepted', emailAdvice.get('rodoAccepted'));
          model.set('mobilePhonePrefix', emailAdvice.get('mobilePhonePrefix'));
          model.set('mobilePhoneNumber', emailAdvice.get('mobilePhoneNumber'));
          model.set('sent', emailAdvice.get('sent'));
          model.set('edit', true);
        }

        this.sendAction('action', 'transports.modals.email-advice', model);
      },
      showInspecionModal: function (transport) {
        this.set('action', 'showModal');
        this.sendAction('action', 'components.inspection-modal', transport);
      },
      sendEmailNotification: async function (id) {
        this.set('manualNotificationIsLoading', true);
        const transport = this.get('transport');
        const path = this.get('apolloApiService').APOLLO_API.MANUAL_NOTIFICATIONS.SEND;

        try {
          const response = await this.get('apolloApiService').callApolloApi(path, {
            manualNotificationId: id
          });

          if (response.ok) {
            transport.reload().then(() => this.set('manualNotificationIsLoading', false));
          } else {
            console.error(`Błąd podczas wysyłania powiadomienia ręcznęgo do transportu id ${transport.id}`);
            this.set('manualNotificationIsLoading', false);
          }
        } catch (error) {
          console.error(`Błąd podczas wysyłania powiadomienia ręcznęgo do transportu id ${transport.id}`);
          this.set('manualNotificationIsLoading', false);
          console.error(error);
        }
      },
      navigateToCourierTracking: async function () {
        const courierName = this.get('transport.transaction.carrier.name');
        const trackingNumber = this.get('transport.courierOrder.trackingNumber');

        if (courierName) {
          console.debug('Znaleźliśmy dane transakcji w keszu embera...');
          window.location.replace(this._trackingListLinkFactory(courierName, trackingNumber));
        }

        console.debug('Nie znaleźliśmy danych transakcji w keszu embera, pobieramy z bazy...');
        const courierNameFromDb = await this._getCourierName();

        if (!courierNameFromDb) {
          return;
        }

        window.location.replace(this._trackingListLinkFactory(courierNameFromDb, trackingNumber));
      },
      openCreateAdviceModal: function (transport) {
        this.openCreateAdviceModal(transport);
      }
    }
  });

  _exports.default = _default;
});